.link {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: -30px;
}
