
.Home{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    user-select: none;
}
