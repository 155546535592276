.Video{
    height: 100%;
    width: 100%;
    margin: 30px 0px;
    padding:20px 30px;
}

.inside-video-home-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 10px;
}

/* top */

.top-video-home-container{
    height: 100%;
    width: 100%;
    border-radius: 30px;
    border: 1px solid black;
    overflow: hidden;
}
.inside-top-video-home-container{
    display: grid;
    grid-template-columns: 50% 50%;
}

.top-left-video-description{
    width: 100%;
    height: 100%;
}
.inside-top-left-video-description-container{
    height: 100%;
    width: 100%;
    padding: 30px;
    background: black;
    position: relative;
}
.top-left-video-container-title{
    background: none;
    color: white;
    border: 1px solid white;
    padding: 10px;
    border-radius: 30px;
    width: 30%;
    text-align: center;
    margin-bottom: 30px;
}

.top-left-video-container-youtube-description{
    background: none;
    color: white;
    font-size: 30px;
    margin-bottom: 20%;
    text-align: 700;
}

.video-watch-now-button{
    position: absolute;
    bottom: 20px;
    left: 30px;
    padding: 10px;
    border: 1px solid white;
    background: white;
    border-radius: 30px;
    cursor: pointer;
}
.video-watch-now{
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
}



.top-right-video-container{
    width: 100%;
    height: 100%;
}
.inside-top-right-video-container{
    width: 100%;
    height: 100%;
}
.youtube-embedded-video{
    height: 100%;
    width: 100%;
    min-height: 300px;
    min-width: 200px;
}

/* bottom */

.bottom-video-home-container{
    height: 100%;
    width: 100%;
}
.inside-bottom-video-home-container{
    /* display: grid;
    grid-template-columns: 50% 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.bottom-video-home-youtube-container{
    height: 100%;
    width: 100%;
    border: 1px solid black;
    overflow: hidden;
    border-radius: 30px;
}

.embedded-youtube-video-container{
    width: 100%;
}

.youtube-video-desciption-bottom{
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


@media screen and (max-width:1126px) {
    .top-left-video-container-title {
        width: 40%;
    }
}

@media screen and (max-width:800px) {
    .inside-top-video-home-container {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: 50% 50%;
    }
}

@media screen and (max-width:690px) {
    .Video {
        padding: 20px 15px;
    }
    .inside-bottom-video-home-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width:480px) {

    .top-left-video-container-youtube-description {
        font-size: 20px;
    }
    .top-left-video-container-title {
        width: 50%;
        font-size: 14px;
    }
    .video-watch-now-button {
        bottom: 15px;
        left: 30px;
        font-size: 14px;
    }
}